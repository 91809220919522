document.addEventListener("DOMContentLoaded", function () {
  const body = document.body;

  // Dropdown
  const themeToggle = document.querySelector("#setting-theme");
  var darkIcon = '<i class="bx bx-moon"></i>';
  var lightIcon = '<i class="bx bx-sun"></i>';

  if (themeToggle) {
    if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
      document.body.classList.add("theme-dark");
      themeToggle.innerHTML = lightIcon;
    } else {
      document.body.classList.add("theme-light");
      themeToggle.innerHTML = darkIcon;
    }

    themeToggle.addEventListener("click", function () {
      if (body.classList.contains("theme-dark")) {
        document.body.classList.remove("theme-dark");
        document.body.classList.add("theme-light");
        themeToggle.innerHTML = darkIcon;
      } else {
        document.body.classList.remove("theme-light");
        document.body.classList.add("theme-dark");
        themeToggle.innerHTML = lightIcon;
      }
    });
  }

  const dropdownToggles = document.querySelectorAll("*[attr-dd-target]");
  if (dropdownToggles) {
    dropdownToggles.forEach((dropdown) => {
      const target = dropdown.getAttribute("attr-dd-target") ?? "";
      const targetElement = document.querySelector(target);
      if (!targetElement) return;

      dropdown.addEventListener("click", function () {
        targetElement.classList.toggle("dropdown--active");
      });
    });
  }

  // Language picker
  const langPickerOptions = document.querySelectorAll(
    "#language-dropdown > a"
  ) as NodeListOf<HTMLAnchorElement>;

  if (langPickerOptions) {
    const languagePickerForm = document.querySelector(
      "#language-form"
    ) as HTMLFormElement;
    const languagePickerSelect = languagePickerForm.querySelector(
      "select"
    ) as HTMLSelectElement;

    if (languagePickerSelect && languagePickerForm) {
      langPickerOptions.forEach((option) => {
        option.addEventListener("click", function () {
          languagePickerSelect.setAttribute(
            "value",
            option.getAttribute("data-lang") ?? ""
          );
          languagePickerSelect.value = option.getAttribute("data-lang") ?? "";

          languagePickerForm.submit();
        });
      });
    }
  }
});
